import React from 'react';
import Router from './router';
import { theme } from './components/styles/theme';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
// import Box from '@mui/material/Box';
// import NavBar from './components/Navbar.jsx';
// import createTheme from '@mui/material/styles/createTheme';

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Router />
        </ThemeProvider>
    )
};
