import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Loader from "../utils/loader";
// import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { nthPosition } from "../Contestant/Profile";
import { titleCase } from "../Contestant/Profile";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import Paper from "@mui/material/Paper";
import CountDown from "../utils/countdown";
import "./style.css";
import { Divider } from "@mui/material";
const url = process.env.REACT_APP_API_GET_TOP_USERS;
// const url = "http://localhost:8080/api/user/top-contestants";

export default function TopContestants() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const { day, hour, min, sec } = CountDown("August 9 2024");

  useEffect(() => {
    axios
      .get(url)
      .then(({ data }) => {
        // console.log(data);
        setUsers(data.contestants);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container
      maxWidth="md"
      sx={{
        marginTop: 13,
      }}
    >
      <Loader load={loading} />
      <Box sx={{ textAlign: "center", marginBottom: 2 }}>
        <Typography variant="h6">MyChild&I Elite Kids</Typography>
        <Typography>
          {/* WINNERS will emerge in {day}d & {hour}h: {min}m: {sec}s{" "} */}
          These are the winners of MyChild&I Contest
        </Typography>
      </Box>
      <Box>
        {users.map((user, index) => (
          <Paper elevation={3} key={index}>
            <Box
              sx={{
                width: "100%",
                height: {
                  md: 200,
                  xs: 100,
                },
                backgroundColor: "#fff",
                borderRadius: 5,
                display: "flex",
                marginBottom: {
                  md: 4,
                  xs: 2,
                },
                padding: 1,
                paddingRight: 0,
              }}
            >
              <Box
                sx={{ width: { md: "20%", xs: "30%", overflow: "hidden" } }}
                className="top_contestants"
              >
                <img
                  src={`https://leadritehub.com/${user.picture}`}
                  alt=""
                  width={window.screen.width <= 450 ? "100" : "200"}
                />
              </Box>

              <Divider
                orientation="vertical"
                flexItem
                sx={{ marginX: { md: 5, xs: 1 } }}
              />

              <Box>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{
                    fontSize: {
                      md: 40,
                      xs: 15,
                      lineHeight: 1,
                    },
                  }}
                >
                  {titleCase(user.name)}
                </Typography>

                {index === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      marginY: { md: 2, xs: 0 },
                    }}
                  >
                    <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>
                      {/* Top Leading Contestant{" "} */}
                      Grand Winner
                    </Typography>
                    <EmojiEventsIcon color="primary" />
                  </Box>
                )}
                {index !== 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      marginY: { md: 2, xs: 0 },
                    }}
                  >
                    <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>
                      {nthPosition(index)} Runner-up{" "}
                    </Typography>
                    <MilitaryTechIcon color="primary" />
                  </Box>
                )}

                <Button
                  variant="outlined"
                  size="small"
                  href={`contestant/${user.id}`}
                >
                  {" "}
                  see profile{" "}
                </Button>
              </Box>
            </Box>
          </Paper>
        ))}
      </Box>
    </Container>
  );
}
