import React, { useState } from "react";
import axios from "axios";
import PaystackAuth from "./paystackAuth";
// import FlutterwaveAuth from './flutterwaveAuth';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import VoterDialog from "../voterDialog";
import { Button } from "@mui/material";
import { titleCase } from "../Profile";
import { makeStyles } from "@mui/styles";

const vote_update_url = process.env.REACT_APP_API_UPDATE_VOTE;
const log_update_url = process.env.REACT_APP_API_LOG_UPDATE;
// const vote_update_url = "http://127.0.0.1:8080/api/user/update-vote";
// const log_update_url = "http://127.0.0.1:8080/api/user/update-log";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  main: {
    fontWeight: "600",
    fontSize: "25px",
    color: "#333333",
  },
  subtitle: {
    color: "gray",
    fontWeight: "600",
  },
}));

export default function Payment({ location }) {
  const { id, name, currentVote, vote } = location.state;
  const classes = useStyles();

  const [success, setSuccess] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [paymentSuccessSnapbar, setPaymentSuccessSnapbar] = useState(false);
  const [infoSnapbar, setInfoSnapbar] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [logID, setLogID] = useState("");

  const handlePaymentSuccess = () => {
    console.log("payment success");
    setSuccess(true);
    setPaymentSuccessSnapbar(true);

    axios
      .put(vote_update_url, {
        id,
        time: Date.now().toString(),
        voteCount: vote,
        voter: "Anonymous",
        byAdmin: false,
      })
      .then(function ({ status, data }) {
        // console.log(data)
        if (status === 201) {
          const log = data.log;
          setLogID(log._id);
          setDialog(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePaymentFail = () => {
    setPaymentSuccessSnapbar(true);
    setTimeout(() => {
      window.location.replace(`/contestant/${id}`);
    }, 5000);
  };

  const updateVoter = (voter, anonymity) => {
    setDialog(false);
    setDisabled(true);

    if (!anonymity && voter) {
      axios
        .put(log_update_url, { id, logID, voter })
        .then(function (response) {
          console.log(response);
          alert("Voter's name was updated successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    setPaymentSuccessSnapbar(false);
    setInfoSnapbar(true);
    setTimeout(() => {
      window.location.replace(`/contestant/${id}`);
    }, 6000);
  };

  const closePaymentSuccess = (event, reason) => {
    if (reason === "clickaway") return;
    setPaymentSuccessSnapbar(false);
  };

  const closeInfoSnapbar = () => {
    setInfoSnapbar(false);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 13 }}>
      <Box>
        <Typography textAlign="center" sx={{ fontWeight: "600" }}>
          VOTE SUMMARY
        </Typography>
        <Box
          sx={{
            marginTop: 2,
            padding: 2,
            borderRadius: 2,
            backgroundColor: "#F2F2F2",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <div>
            <Typography variant="h5" className={classes.main}>
              {titleCase(name)}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Contestant's Name
            </Typography>
          </div>
          <div>
            <Typography variant="h5" className={classes.main}>
              {id}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Contestant's ID
            </Typography>
          </div>
          <div>
            <Typography variant="h5" className={classes.main}>
              {currentVote}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Current vote count
            </Typography>
          </div>
          <Divider />
          <div>
            <Typography variant="h5" className={classes.main}>
              {vote}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Number of votes selected
            </Typography>
          </div>
          <div>
            <Typography variant="h5" className={classes.main}>
              {currentVote + vote}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Votes count after casting this vote
            </Typography>
          </div>
          <PaystackAuth
            handlePaymentSuccess={handlePaymentSuccess}
            handlePaymentFail={handlePaymentFail}
            disabled={disabled}
            vote={vote}
            id={id}
          />
        </Box>
        <Box sx={{ margin: "0 auto", my: 2 }}>
          <Button href={`/contestant/${id}`}>Go Back</Button>
        </Box>
      </Box>
      <Snackbar open={paymentSuccessSnapbar} onClose={closePaymentSuccess}>
        <Alert
          onClose={closePaymentSuccess}
          severity={success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {success
            ? "Voting was successful! - Thank you"
            : "Action failed! - Please try again. Refreshing page now..."}
        </Alert>
      </Snackbar>

      <Snackbar open={infoSnapbar} onClose={closeInfoSnapbar}>
        <Alert severity="info" sx={{ width: "100%" }}>
          <AlertTitle>Info</AlertTitle>
          Thank you! The votes has been updated for this contestant.
          <strong>
            This page will be refreshed to reflect the updated vote.
          </strong>
        </Alert>
      </Snackbar>
      <VoterDialog open={dialog} updateVoter={updateVoter} />
    </Container>
  );
}
