import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import Notifications from "@mui/icons-material/Notifications";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import InfoIcon from "@mui/icons-material/Info";
import ArticleIcon from "@mui/icons-material/Article";
import ButtonGroup from "@mui/material/ButtonGroup";
import logo from "./images/logo.png";
const drawerWidth = 240;

export default function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const container =
    window !== undefined ? () => window().document.body : undefined;
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <List>
        <ListItem>
          <ListItemButton href="/">
            <ListItemIcon>
              {" "}
              <HomeIcon />{" "}
            </ListItemIcon>
            <ListItemText primary="HOME" />
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton href="/about">
            <ListItemIcon>
              {" "}
              <InfoIcon />{" "}
            </ListItemIcon>
            <ListItemText primary="ABOUT" />
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton href="/contestants">
            <ListItemIcon>
              {" "}
              <PeopleIcon />{" "}
            </ListItemIcon>
            <ListItemText primary="CONTESTANTS" />
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton href="/elite-kids">
            <ListItemIcon>
              {" "}
              <QueryStatsIcon />{" "}
            </ListItemIcon>
            <ListItemText primary="ELITE KIDS" />
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton href="/terms-conditions">
            <ListItemIcon>
              {" "}
              <ArticleIcon />{" "}
            </ListItemIcon>
            <ListItemText primary="T&C" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box>
      <AppBar position="fixed" sx={{ zIndex: 5, backgroundColor: "#262626" }}>
        <Toolbar>
          <Box
            width="100%"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ display: { xs: "flex", md: "none" } }}
              onClick={() => {
                setMobileOpen(true);
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 2, marginY: 1 }}
            >
              <img src={logo} width="50" alt="logo" />
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", md: "block" } }}
              >
                MyChild&I
              </Typography>
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <ButtonGroup
                variant="text"
                aria-label="text button group"
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <Button href="/" sx={{ color: "white" }}>
                  HOME
                </Button>
                <Button href="/about" sx={{ color: "white" }}>
                  ABOUT
                </Button>
                <Button href="/contestants" sx={{ color: "white" }}>
                  CONTESTANTS
                </Button>
                <Button href="/elite-kids" sx={{ color: "white" }}>
                  ELITE KIDS
                </Button>
                <Button href="/terms-conditions" sx={{ color: "white" }}>
                  T&C
                </Button>
                <Button href="/register" sx={{ color: "white" }}>
                  REGISTER
                </Button>
              </ButtonGroup>
            </Box>
            <Box marginRight={2}>
              <Badge badgeContent={0} color="error">
                <Notifications />
              </Badge>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
