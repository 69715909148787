import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const steps = [
  {
    label: "Register:",
    description: `Registration gets your child into the contest, ergo, making your child
        MyChild&I Contestant.`,
  },
  {
    label: "Campaign:",
    description: `When the contest commences, a unique campaign poster will be sent to you.
        The poster is intended to help your child campaign for vote from
        families and friends.`,
  },
  {
    label: "Vote:",
    description: `Voting is the sole determinate to your child's victory, we advise campaigning 
        and voting as much as possible, even after you've gotten the required number of votes, this
        will in turn give you an edge at the final.`,
  },
  {
    label: "Win:",
    description: `The contestant with the highest accumulated number of votes emerges as the winner.
        The winner will be given a cash prize of N2,000,000, the 1st and 2nd Runners up gets a cash prize of N1,000,000 and N500,000 cash prize. There'll also be
        wonderful consolation prizes for the top 7 contestants.`,
  },
];

export default function Info() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box>
      <Typography marginBottom={2} fontWeight="fontWeightBold">
        About MyChild&I Contest:
      </Typography>
      <Typography marginBottom={2}>
        Welcome to MyChild&I Contest, an initiative celebrating the bond between
        Nigerian children and their parents. This engaging contest provides a
        platform for children and parents to showcase the love, affection, and
        teamwork within their relationship.
      </Typography>
      <Typography marginBottom={2}>
        Organized and sponsored by Leadrite Kiddies Hub, an enterprise committed
        to bringing joy to underprivileged children and Nigerian families, the
        contest aims to offer children an opportunity to demonstrate their
        talents while competing for exciting prizes.
      </Typography>
      <Typography marginBottom={2}>
        Leadrite Kiddies Hub, a registered Nigerian company (RC: 3561377), is
        located at Irama Complex, Plot 4, Sa'adu, Zungar Avenue, off Wole
        Soyinka Avenue, 4th Avenue, Gwarinpa, Abuja.
      </Typography>
      <Typography marginBottom={2}>
        Through events like MyChild&I Contest and the incorporation of enjoyable
        activities, we strive to reinvigorate the love, collaboration, and
        affection between Nigerian parents and their children. Additionally, we
        aim to provide a platform for caring parents to demonstrate their
        unwavering love for their children.
      </Typography>
      <Typography marginBottom={2}>
        The contest unfolds in three stages: Stage 1, Stage 2, and the Final,
        each spanning 7 days, totaling 3 weeks collectively.
      </Typography>
      <Typography marginBottom={2}>
        In Stage 1, contestants aim to garner a minimum of 350 votes (N50 each)
        to progress to Stage 2. Stage 1 qualifiers receive a courtesy gift pack
        delivered to their homes nationwide.
      </Typography>
      <Typography marginBottom={2}>
        During Stage 2, contestants target at least 500 votes. This stage
        features enjoyable activities that parents are encouraged to participate
        in, providing contestants an opportunity to earn free votes, potentially
        up to 500, making payment for votes in Stage 2 optional. All successful
        Stage 2 participants advance to the Final.
      </Typography>
      <Typography marginBottom={2}>
        At the Final, there's no predetermined number of votes; the contestant
        with the highest vote count emerges as the winner. Additionally, there
        are fantastic consolation prizes for the top 7 contestants, aside from
        the grand winner.
      </Typography>
      <Typography>The prizes to be won include:</Typography>
      <ol>
        <li>2024 Parent and Child of the Year Award</li>
        <li>N3,500,000 Million Naira cash prize</li>
        <li>Kid's iPad and Gift packs </li>
        <li>Family package</li>
        <li>Other great benefits</li>
      </ol>
      <Typography marginBottom={2}>
        Leadrite Kiddies Hub wishes all our wonderful contestants the best of
        luck in this journey.
      </Typography>

      <Box>
        <Typography textAlign="center" fontWeight="fontWeightBold">
          Activity Roadmap:
        </Typography>
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            bgcolor: "background.default",
          }}
        >
          <Typography fontWeight="fontWeightBold">
            {steps[activeStep].label}
          </Typography>
        </Paper>
        <Box sx={{ height: 150, maxWidth: 400, width: "100%" }}>
          {steps[activeStep].description}
        </Box>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>
    </Box>
  );
}
