import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Register from "./components/Register/Register";
import About from "./components/About";
import Contestant from "./components/Contestant";
import Payment from "./components/Contestant/payment/payment.jsx";
import BankInfo from "./components/Contestant/payment/bankInfo.jsx";
import NavBar from "./components/Navbar.jsx";
import TandC from "./components/T&C";
// import Login from './components/admin';
// import ComingSoon from "./components/comingSoon";
import TopContestants from "./components/Contestants/topContestants";
import UpdatePhoto from "./components/admin/updatePhoto";
import AddVote from "./components/admin/AddVote";
// import TempContestant from "./components/tempPage.jsx";
import Contestants from "./components/Contestants/contestants";
// import ComingSoon from "./components/comingSoon";

export default function Router() {
  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contestant/:id" component={Contestant} />
        <Route exact path="/contestants" component={Contestants} />
        <Route exact path="/elite-kids" component={TopContestants} />
        <Route exact path="/payment-secure" component={Payment} />
        <Route exact path="/bank-info" component={BankInfo} />
        <Route exact path="/terms-conditions" component={TandC} />
        {/* <Route
          exact
          path="/elite-kids"
          render={(props) => <ComingSoon page="elite-kids" {...props} />}
        /> */}
        <Route exact path="/update-picture" component={UpdatePhoto} />
        {/* <Route exact path="/lwkmd" component={AddVote} /> */}
        {/* <Route path="*" component={NotFound} /> */}
      </Switch>
    </BrowserRouter>
  );
}
