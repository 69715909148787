import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Loader from "../utils/loader";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import "./style.css";
const url = process.env.REACT_APP_API_GET_ALL_USER;
// const url = "http://127.0.0.1:8080/api/user/get-users";

export default function Contestants() {
  const [load, setLoad] = useState(true);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setUsers([]);
    try {
      // localhost:8080/api/user/get-users?page=2
      axios
        .get(`${url}?page=${page}`)
        .then(({ data }) => {
          // console.log(data);
          setUsers(data);
          setLoad(false);
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [page]);

  function onclickEffect(event, page) {
    setLoad(true);
    setPage(page);
  }

  return (
    <Container maxWidth="md" padding={2}>
      <Box sx={{ marginTop: 12 }}></Box>
      <Loader load={load} />
      <Typography sx={{ mb: 2, fontWeight: "bold" }}>
        Showing all the Finalists
      </Typography>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {users.map((user, index) => (
          <Grid item xs={2} sm={3} md={3} key={index}>
            <Paper elevation={1}>
              <Box
                sx={{
                  height: 150,
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  borderRadius: 2,
                }}
              >
                <img
                  src={`https://leadritehub.com/${user.picture}`}
                  alt=""
                  className="card-image"
                />
              </Box>
              <Box
                sx={{
                  padding: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: {
                    xs: 150,
                    md: 200,
                  },
                  textAlign: "center",
                }}
              >
                {user.name}
                <Typography fontWeight="bold">
                  {user.votes.stage3} votes
                </Typography>
                <Button
                  sx={{ marginTop: 1, width: "inherit" }}
                  variant="outlined"
                  size="small"
                  href={`/contestant/${user.id}`}
                >
                  View
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", marginY: 3 }}>
        <Pagination color="primary" count={2} onChange={onclickEffect} />
      </Box>
    </Container>
  );
}
