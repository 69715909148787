import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Loader from './utils/loader'

export default function TandC() {

    return (
        <Container padding={1} sx={{marginTop: 12}}>
            <Box>
                <Typography fontWeight="fontWeightBold" textAlign="center">Terms and Conditions Guide</Typography>
                <Typography fontWeight="fontWeightBold" gutterBottom>1. Introduction:</Typography>
                <Typography>Welcome to Leadrite Kiddies Hub (“Company”, “we”, “our”, “us”)!</Typography>
                <Typography gutterBottom>
                    These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website located at www.leadritehub.com 
                    (together or individually “Service”) operated by Leadrite Kiddies Hub.
                </Typography>
                <Typography gutterBottom>
                    Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose 
                    information that results from your use of our web pages.
                </Typography>
                <Typography gutterBottom>
                    Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have 
                    read and understood Agreements, and agree to be bound of them.
                </Typography>
                <Typography gutterBottom>
                    If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let 
                    us know by emailing at leadritehub@gmail.com so we can try to find a solution. These Terms apply to all visitors, 
                    users and others who wish to access or use Service.
                </Typography>
                <Typography fontWeight="fontWeightBold">2. Purchases:</Typography>
                <Typography gutterBottom>
                    If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked 
                    to supply certain information relevant to your Purchase including but not limited to, your credit or debit card 
                    number, the expiration date of your card, your billing address, and your shipping information.
                </Typography>
                <Typography gutterBottom>
                    You represent and warrant that: (i) you have the legal right to use any card(s) or other payment method(s) in 
                    connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.
                </Typography>
                <Typography gutterBottom>
                    We may employ the use of third party services for the purpose of facilitating payment and the completion of 
                    Purchases. By submitting your information, you grant us the right to provide the information to these third 
                    parties subject to our Privacy Policy.
                </Typography>
                <Typography gutterBottom>
                    We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product 
                    or service availability, errors in the description or price of the product or service, error in your order or other 
                    reasons.
                </Typography>
                <Typography gutterBottom>
                    We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.
                </Typography>

                <Typography gutterBottom fontWeight="fontWeightBold">3. Prohibited Uses:</Typography>
                <Typography gutterBottom>You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:</Typography>
                <ul>
                    <li>In any way that violates any applicable national or international law or regulation.</li>
                    <li>
                        For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them 
                        to inappropriate content or otherwise.
                    </li>
                    <li>
                        To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, 
                        “chain letter,” “spam,” or any other similar solicitation.
                    </li>
                    <li>
                        To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.
                    </li>
                    <li>
                        In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, 
                        or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
                    <li>
                        Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which 
                        Service is stored, or any server, computer, or database connected to Service.
                    </li>
                </ul>
                <Typography gutterBottom fontWeight="fontWeightBold">4. Intellectual Property:</Typography>
                <Typography gutterBottom>
                    Service and its original content (excluding Content provided by users), features and functionality are and will remain 
                    the exclusive property of Leadrite Kiddies Hub and its licensors. Service is protected by copyright, trademark, and other 
                    laws of and foreign countries. Our trademarks may not be used in connection with any product or service without the 
                    prior written consent of Leadrite Kiddies Hub.
                </Typography>
                <Typography gutterBottom fontWeight="fontWeightBold">5. Copyright Policy:</Typography>
                <Typography gutterBottom> 
                    We respect the intellectual property rights of others. It is our policy to respond to any claim that Content 
                    posted on Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.
                </Typography>
                <Typography gutterBottom>
                    If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been 
                    copied in a way that constitutes copyright infringement, please submit your claim via email to leadritehub@gmail.com, 
                    with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged 
                    Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”
                </Typography>
                <Typography gutterBottom>
                    You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims 
                    on the infringement of any Content found on and/or through Service on your copyright.
                </Typography>
                <Typography gutterBottom fontWeight="fontWeightBold">6. Links To Other Web Sites:</Typography>
                <Typography gutterBottom>
                    Our Service may contain links to third party web sites or services that are not owned or controlled by Leadrite Kiddies Hub.
                </Typography>
                <Typography gutterBottom>
                    Leadrite Kiddies Hub has no control over, and assumes no responsibility for the content, privacy policies, or 
                    practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals 
                    or their websites.
                </Typography>
                <Typography gutterBottom>
                    YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS 
                    CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE 
                    ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
                </Typography>
                <Typography gutterBottom>
                    WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
                </Typography>
                <Typography gutterBottom fontWeight="fontWeightBold">7. Refunds:</Typography>
                <Typography gutterBottom>Money used to purchase votes for contestant(s) will not be refunded with or without demand.</Typography>
                <Typography gutterBottom fontWeight="fontWeightBold">8. Termination:</Typography>
                <Typography gutterBottom>
                    We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under 
                    our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.
                </Typography>
                <Typography gutterBottom>If you wish to terminate your account, you may simply discontinue using the platform.</Typography>
                <Typography gutterBottom>
                    All provisions of Terms which by their nature should survive termination shall survive termination, including, without 
                    limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                </Typography>
                <Typography gutterBottom fontWeight="fontWeightBold">9. Changes To Service:</Typography>
                <Typography gutterBottom>
                    We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion 
                    without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. 
                    From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.
                </Typography>
                <Typography gutterBottom fontWeight="fontWeightBold">10. Acknowledgement:</Typography>
                <Typography gutterBottom>
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
                </Typography>
                <Typography gutterBottom>10. Contact Us</Typography>
                <Typography gutterBottom>Please send your feedback, comments, requests for technical support by email: leadritehub@gmail.com.</Typography>
            </Box>
        </Container>
    )
}
