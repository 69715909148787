import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HistoryIcon from "@mui/icons-material/History";
import InfoIcon from "@mui/icons-material/Info";
import Profile from "./Profile";
import Vote from "./Vote";
import Recent from "./Recent";
import Info from "./Info";
import VoteModal from "./voteModal";
import { UserContext } from "./index";

export default function Contestant() {
  const [value, setValue] = useState(0);
  const { user } = useContext(UserContext);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenModal(true);
    }, 5000);
  }, []);

  function handleCloseModal() {
    setOpenModal(false);
  }

  return (
    <Box sx={{ pb: 7 }}>
      {/* {user.votes.stage2 < 500 && (
        <VoteModal
          user={user}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
        />
      )} */}
      <Box>
        {value === 0 && <Profile />}
        {/* {value === 1 && <Vote />} */}
        {value === 1 && <Recent />}
        {value === 2 && <Info />}
      </Box>

      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="PROFILE"
            icon={<AccountCircleIcon />}
          />
          {/* <BottomNavigationAction label="VOTE" icon={<FavoriteIcon />} /> */}
          <BottomNavigationAction label="HISTORY" icon={<HistoryIcon />} />
          <BottomNavigationAction label="INFO" icon={<InfoIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
