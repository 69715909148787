import React, { useContext, useState } from "react";
import { UserContext } from "./index";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import useCountDown from "../utils/countdown";

export function nthPosition(n) {
  return (
    [`${n}st`, `${n}nd`, `${n}rd`][((((n + 90) % 100) - 10) % 10) - 1] ||
    `${n}th`
  );
}

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export default function Profile() {
  const { user } = useContext(UserContext);
  const { day, hour, min, sec } = useCountDown("August 9 2024");

  const [open, setOpen] = useState(false);
  const link = window.location.href;

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  return (
    <Box>
      {/* <Typography
        textAlign="center"
        variant="subtitle2"
        fontWeight="600"
        sx={{ mb: 1 }}
      >
        STAGE 2: {user.votes.stage2} votes
      </Typography> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          justifyItems: "stretch",
          // backgroundColor: "primary.main",
          backgroundColor: "gray",
          borderRadius: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gap: 1,
            color: "#fff",
          }}
        >
          <Typography variant="body2" fontWeight="600">
            Final:
          </Typography>
          <Typography variant="h6" fontWeight="600">
            {user.votes.stage3} {user.votes.stage3 > 1 ? "votes" : "vote"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gap: 1,
            color: "#fff",
          }}
        >
          <Typography variant="body2" fontWeight="600">
            Position:
          </Typography>
          <Typography variant="h6" fontWeight="600">
            {nthPosition(user.position)}
          </Typography>
        </Box>
      </Box>

      {user.position === 1 && (
        <Typography
          textAlign="center"
          variant="subtitle2"
          lineHeight={1.2}
          marginY={1}
        >
          Congratulations! <strong> {titleCase(user.name)} </strong> is
          currently the best performing contestant.
        </Typography>
      )}

      {/* {user.position > 1 && user.position < 31 && (
        <Typography
          textAlign="center"
          variant="subtitle2"
          lineHeight={1.2}
          gutterBottom
        >
          <strong>Tip: </strong>
          {titleCase(user.name)} needs atleast {user.competeScore} votes to
          claim the <strong>{nthPosition(user.position - 1)}</strong> position.
        </Typography>
      )} */}

      {/*
      {user.votes.stage1 < 200 && (
        <Typography
          textAlign="justify"
          variant="subtitle2"
          lineHeight={1.2}
          marginY={1}
        >
          Need <strong>{200 - user.votes.stage1} </strong> more{" "}
          {user.votes.stage1 > 1 ? "votes" : "vote"} to access{" "}
          <strong> {titleCase(user.name)} </strong>'s stage 1 prize pack - the
          gift will be home delivered
        </Typography>
      )}

      
      {user.votes.stage1 >= 200 && (
        <Typography
          // textAlign="justify"
          variant="subtitle2"
          lineHeight={1.2}
          marginY={1}
        >
          <strong>Note:</strong> No voting activity in stage 2. You are advised
          to participate in the ongoing activity
        </Typography>
      )} */}

      <Typography textAlign="center" fontWeight="600">
        The Final has ended:{" "}
        <strong>
          {day}d & {hour}h: {min}m: {sec}s
        </strong>
      </Typography>
      <Box
        sx={{
          border: 4,
          borderColor: "custom.backgroundGray",
          height: 350,
          overflow: "hidden",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          borderRadius: 2,
        }}
      >
        <img
          src={`https://leadritehub.com/${user.picture}`}
          alt=""
          width="400"
        />
      </Box>
      <Box textAlign="center" marginY={2}>
        <Typography variant="h4" fontWeight="600" gutterBottom>
          {titleCase(user.name)}
        </Typography>
        <Typography variant="subtitle1" lineHeight={1.2}>
          {user.description
            ? user.description
            : `${titleCase(user.name)} deserves to win because ${
                user.gender === "Male" ? "he" : "she"
              }'s a great child who deserve every good thing in life`}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f2f2f2",
          padding: 1,
          textAlign: "center",
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" fontWeight="600">
          Help by sharing
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={1}>
          <WhatsappShareButton
            url={link}
            title={`Hello, please help by voting for ${user.name} on MyChild&I Contest`}
            separator=" | "
          >
            <WhatsAppIcon />
          </WhatsappShareButton>
          <FacebookShareButton
            url={link}
            quote={`Help by voting for ${user.name} on MyChild&I Contest`}
            hashtag="#MyChild&I #contest"
          >
            <FacebookIcon />
          </FacebookShareButton>
        </Stack>
        <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
          <Box
            sx={{
              border: 2,
              borderColor: "#919191",
              width: {
                xs: "75%",
                md: "40%",
              },
              backgroundColor: "background.default",
              overflow: "scroll",
              padding: 0.5,
              borderRadius: 1,
            }}
          >
            <Typography variant="body1" fontWeight="fontWeightMedium">
              {link}
            </Typography>
          </Box>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Box>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied"
                placement="top"
              >
                <IconButton onClick={handleCopy} aria-label="instagram">
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </ClickAwayListener>
        </Box>
      </Box>
      <Typography marginY={2}>
        To vote for this contestant, kindly click <strong> "VOTE" </strong> from
        the menu options below and follow the prompt. You can click any other
        menu option to see its corresponding content.
      </Typography>
    </Box>
  );
}
