import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import whatsapp from "./image/whatsapp.png";
import paymentSample from "./image/payment-sample.jpeg";
import { titleCase } from "../Profile";

export default function BankInfo({ location }) {
  const { id, name, vote } = location.state;

  return (
    <Container maxWidth="sm" sx={{ padding: 2, marginTop: 10 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          textAlign: "justify",
        }}
      >
        <Typography>
          To proceed with your vote, kindly make use of the bank information
          shown below:
        </Typography>
        <Typography>
          Account Name: <strong>Leadrite Kiddies Hub</strong>
          <br />
          Account No: <strong>1221812034</strong>
          <br />
          Bank: <strong>Zenith Bank</strong>
          <br />
          Amount: <strong>NGN {vote * 50}</strong>
        </Typography>
        <Typography>
          After making the deposit, kindly forward the{" "}
          <strong>
            {" "}
            payment receipt, amount, sender's name, contestant’s name and ID{" "}
          </strong>{" "}
          to our whatsapp account by tapping on the whatsapp icon at the
          bottom-left corner of your screen, for prompt response and vote
          update.
        </Typography>
        <ol>
          <li>Payment receipt</li>
          <li>Amount</li>
          <li>
            Contestant's name <strong>({titleCase(name)})</strong>
          </li>
          <li>
            Contestant's ID.{" "}
            <strong>
              ({titleCase(name)}'s ID is {id})
            </strong>
          </li>
          <li>Voter's name (Your name)</li>
        </ol>
        <Typography>
          <strong>For example:</strong>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={paymentSample} alt="demostration" width="300" />
        </Box>
        <Typography>
          Upon verification, the corresponding vote(s) will be updated
          immediately and you'll be notified via a whatsapp response.
        </Typography>
        <Typography>
          <strong>Note:</strong> Due to a high number of votes confirmation
          requests we get sometimes, we may not respond immediately, but be rest
          assured your receipt will be tended to and all votes will be accounted
          for.
        </Typography>
        <Button href={`contestant/${id}`} sx={{ fontWeight: "600" }}>
          back to contestant page
        </Button>
        <Box sx={{ position: "fixed", bottom: 10, right: 10 }}>
          <IconButton
            size="large"
            aria-label="whatsapp"
            href="https://wa.me/message/5G724TL4IRYYC1"
          >
            <img src={whatsapp} width="35" alt="" />
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
}
