import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Loader from "./utils/loader";

const About = () => {
  const [load, setLoad] = useState(true);
  setTimeout(() => {
    setLoad(false);
  }, 1000);
  return (
    <Container maxWidth="md" sx={{ marginTop: 12 }}>
      <Loader load={load} />
      <Box>
        <Typography variant="h5" fontWeight="fontWeightBold" marginBottom={2}>
          Leadrite Kiddies Hub
        </Typography>
        <Box>
          <Typography marginBottom={2} fontWeight="fontWeightBold">
            About "My Child And I" Contest:{" "}
          </Typography>
          <Typography marginBottom={2}>
            MyChild&I Contest is an enterprise, where a loving Parent and Child
            showcases their abilities in partnership, showcases the great
            qualities of a child and how influential a child can be – while
            contending for a mouth-watering prizes.
          </Typography>

          <Typography marginBottom={2}>
            The contest is ultimately organized to showcase parental love for
            their child and how much parents are willing to go for their child.
            MyChild&I Contest is also intended to curb the subpar
            Parent-and-Child bonding moments, and help rejuvenate their bonding
            experiences – this is achieved by the fun activities we create
            during the Contest.
          </Typography>

          <Typography marginBottom={2}>
            Our first contest, held on the March 2023, turned out a huge
            success. The winners of the first edition are:
          </Typography>

          <ol>
            <li>Janet Oluwatobi – Grand winner</li>
            <li>Amanda Esther Nnodu – 1st runner up</li>
            <li>Oluwaseun Desire Esther – 2nd runner up</li>
            <li>Mary George Innocent – 3rd runner up</li>
            <li>Shobowale Dorcas Adeshewa – 4th runner up</li>
            <li>Banire Imran – 5th runner up</li>
          </ol>
          <Typography>The prizes won are:</Typography>
          <ol>
            <li>Parent and Child of the Year awards</li>
            <li>N500,000 cash prize (Grand winner)</li>
            <li>Kiddies iPad</li>
            <li>Kiddies gift pack</li>
            <li>Other great benefits</li>
          </ol>
          <Typography>
            We, the organizers – Leadrite Kiddies Hub, find pride in what we do;
            putting smile on the faces of Nigerian children at scale and their
            families at large. See our{" "}
            <a
              href="https://www.instagram.com/leadritehub/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>{" "}
            page or contact us on{" "}
            <a
              href="https://wa.me/message/RV6VW5F3AIDEC1"
              target="_blank"
              rel="noreferrer"
            >
              Whatsapp
            </a>{" "}
            to learn more.
          </Typography>
        </Box>
        <Box textAlign="center" marginY={3}>
          <Button
            variant="contained"
            component="a"
            href="/"
            sx={{
              fontWeight: "fontWeightBold",
              backgroundColor: "custom.btnBlue",
              ":hover": {
                backgroundColor: "custom.btnBlueDark",
              },
            }}
          >
            Back to Home
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default About;
