import React, { useEffect, useState, createContext } from "react";
import Box from "@mui/material/Box";
import Contestant from "./Contestant";
import Typography from "@mui/material/Typography";
import Loader from "../utils/loader.js";
import { Container } from "@mui/material";
const url = process.env.REACT_APP_API_GETUSER;

// const url = "http://127.0.0.1:8080/api/user/get-user/";

export const UserContext = createContext(null);

export default function Index(props) {
  const userID = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const [user, setUser] = useState({
    user: {
      votes: {
        stage1: 0,
        stage2: 0,
        stage3: 0,
      },
      name: "",
      id: "",
      gender: "",
      description: "",
      picture: "",
      log: [],
      position: 0,
      competeScore: 0,
    },
  });

  useEffect(() => {
    fetch(`${url}${userID}`)
      .then((response) => {
        setLoading(false);

        const checkSuccess = response.status === 200 || response.status === 201;
        setSuccess(checkSuccess);
        if (checkSuccess) {
          return response.json();
        } else {
          throw new Error("Failed to fetch data");
        }
      })
      .then((data) => {
        const { foundUser, position, competeScore } = data;
        setUser({ user: { ...foundUser, position, competeScore } });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [userID]);

  return (
    <Container maxWidth="sm">
      <Loader load={loading} />
      {success ? (
        <UserContext.Provider value={user}>
          <Box marginTop={12}></Box>
          <Contestant />
        </UserContext.Provider>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontWeight="bold" gutterBottom>
            Contestant not found:
          </Typography>
          <Typography>
            {" "}
            This contestant might not have made it to the FINAL of MyChild&I
            contest.
          </Typography>
        </Box>
      )}
    </Container>
  );
}
