import { css } from "@emotion/react";
import DotLoader from "react-spinners/DotLoader";
import "./loader.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = (props) => {
  const properties = {
    loading: props.load,
    color: "#2094d2",
    speedMultiplier: 1,
    css: override,
    size: 100,
  };

  return (
    <div className={properties.loading ? "loader" : "unload"}>
      <div>
        <DotLoader {...properties} />
      </div>
    </div>
  );
};

export default Loader;
