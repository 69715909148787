import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../images/logo.png";
import "./image.css";

export default function Home() {
  return (
    <Box>
      <Stack direction="row">
        <Box
          sx={{
            backgroundColor: "custom.backgroundDark",
            // textAlign: "center",
            flex: 1,
            height: "100vh",
            padding: 3,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box margin={2}>
            <Box marginBottom={3}>
              <img src={logo} alt="logo" className="logo" />
            </Box>
            <Box color="primary.contrastText">
              <Typography
                component="h1"
                variant="h2"
                fontWeight="fontWeightBold"
              >
                Ready to win with your Child?
              </Typography>
              <Typography
                component="h2"
                variant="span"
                marginTop={2}
                fontWeight="fontWeightMedium"
              >
                #MyChild&I Contest
              </Typography>
              <Box marginTop={3}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ marginRight: 2 }}
                  component="a"
                  href="/register"
                >
                  Register
                </Button>
                <Button
                  variant="text"
                  color="inherit"
                  size="large"
                  href="/about"
                >
                  Learn more
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
          flex={2}
          height="100vh"
          overflow="hidden"
          className="hero-img"
        ></Box>
      </Stack>
    </Box>
  );
}
